var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"id":"dashboard-view","fluid":"","tag":"section"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}}),_c('v-card-text',[_c('v-row',{staticClass:"mt-10"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"text-button align-self-center pt-0 mb-9",attrs:{"cols":"3"}},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('messages.userId')))])])]),_c('v-col',{staticClass:"text-button align-self-center pb-0 pt-0",attrs:{"cols":"8"}},[_c('div',[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":"","outlined":"","error-messages":errors[0]},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}})]}}])})],1)])],1),_c('v-row',[_c('v-col',{staticClass:"text-button align-self-center pt-0 mb-9",attrs:{"cols":"3 "}},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('messages.name')))])])]),_c('v-col',{staticClass:"text-button align-self-center pb-0 pt-0",attrs:{"cols":"8"}},[_c('div',[_c('validation-provider',[_c('v-text-field',{attrs:{"clearable":"","outlined":"","disabled":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1)])],1),_c('v-row',[_c('v-col',{staticClass:"text-button align-self-center pt-0 mb-9",attrs:{"cols":"3"}},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('messages.managerId')))])])]),_c('v-col',{staticClass:"text-button align-self-center pb-0 pt-0",attrs:{"cols":"8"}},[_c('div',[_c('validation-provider',[_c('v-text-field',{attrs:{"clearable":"","outlined":"","disabled":""},model:{value:(_vm.managerId),callback:function ($$v) {_vm.managerId=$$v},expression:"managerId"}})],1)],1)])],1),_c('v-row',[_c('v-col',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_vm._l((_vm.fields),function(value,index){return _c('v-row',{key:index,staticClass:"align"},[(value.type !== 'spacer')?_c('v-col',{staticClass:"text-button align-self-center pt-0 mb-9",attrs:{"cols":"3"}},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('messages.' + value.key)))]),_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(value.validate ? value.validate.includes('required') ? '*' : '' : ''))])])]):_vm._e(),_c('v-col',{staticClass:"text-button align-self-center pb-0 pt-0",attrs:{"cols":"8"}},[(value.type === 'textarea')?_c('v-textarea',{attrs:{"clearable":"","outlined":""},model:{value:(value.value),callback:function ($$v) {_vm.$set(value, "value", $$v)},expression:"value.value"}}):(value.type === 'date-text')?_c('validation-provider',{attrs:{"name":value.key,"rules":value.validate ? value.validate : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [('menu' in value)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"name":value.key,"disabled":value.disabled,"clearable":"","outlined":"","autocomplete":"off","error-messages":errors[0],"placeholder":_vm.$t(value.placeholder)},on:{"keydown":_vm.checkDate},scopedSlots:_vm._u([{key:"append",fn:function(){return undefined},proxy:true}],null,true),model:{value:(value.value),callback:function ($$v) {_vm.$set(value, "value", $$v)},expression:"value.value"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:'menu' + value.key,refInFor:true,attrs:{"close-on-content-click":false,"return-value":value.value,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(value, "value", $event)},"update:return-value":function($event){return _vm.$set(value, "value", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-4 mr-3",attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-calendar ")])]}}],null,true),model:{value:(value.menu),callback:function ($$v) {_vm.$set(value, "menu", $$v)},expression:"value.menu"}},[_c('v-date-picker',{attrs:{"locale":"ja-jn","no-title":"","scrollable":"","max":value.max_date ? value.max_date : ''},model:{value:(value.value),callback:function ($$v) {_vm.$set(value, "value", $$v)},expression:"value.value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){value.menu = false}}},[_vm._v(" "+_vm._s(_vm.$t('messages.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs['menu' + value.key][0].save(
                                        value.value
                                      )}}},[_vm._v(" "+_vm._s(_vm.$t('messages.ok'))+" ")])],1)],1)],1)],1)],1):_c('div',[_c('v-text-field',{attrs:{"name":value.key,"clearable":"","disabled":value.disabled,"outlined":"","error-messages":errors[0],"placeholder":_vm.$t(value.placeholder)},on:{"keydown":_vm.checkDate},model:{value:(value.value),callback:function ($$v) {_vm.$set(value, "value", $$v)},expression:"value.value"}})],1)]}}],null,true)}):(value.type === 'select')?_c('validation-provider',{attrs:{"name":value.key,"rules":value.validate ? value.validate : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":value.disabled,"name":value.key,"outlined":"","error-messages":errors[0],"items":value.items,"item-text":"text","item-value":"value","placeholder":_vm.$t(value.placeholder)},model:{value:(value.value),callback:function ($$v) {_vm.$set(value, "value", $$v)},expression:"value.value"}})]}}],null,true)}):(value.type === 'text')?_c('validation-provider',{attrs:{"name":value.key,"rules":value.validate ? value.validate : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":value.disabled,"name":value.key,"outlined":"","error-messages":errors[0],"clearable":"","placeholder":_vm.$t(value.placeholder)},model:{value:(value.value),callback:function ($$v) {_vm.$set(value, "value", $$v)},expression:"value.value"}})]}}],null,true)}):_vm._e()],1)],1)}),_c('v-row',{staticClass:"d-flex justify-end mt-3"},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"large":"","block":"","color":"accent","type":"submit"}},[_c('span',{staticClass:" font-weight-bold text-h5"},[_vm._v(" "+_vm._s(_vm.$t('messages.update'))+" ")])])],1),_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"large":"","block":"","color":"accent"},on:{"click":_vm.cancel}},[_c('span',{staticClass:"text-h5 font-weight-bold pr-3 pl-3"},[_vm._v(_vm._s(_vm.$t('messages.cancel')))])])],1),_c('v-col',{attrs:{"cols":"1"}})],1)],2)]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmitPassword)}}},[_c('v-row',[_c('v-col',{staticClass:"text-button align-self-center pt-0 mb-9",attrs:{"cols":"3"}},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('messages.oldPassword')))])])]),_c('v-col',{staticClass:"text-button align-self-center pb-0 pt-0",attrs:{"cols":"8"}},[_c('div',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","type":"password","outlined":"","error-messages":errors[0]},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})]}}],null,true)})],1)])],1),_c('v-row',[_c('v-col',{staticClass:"text-button align-self-center pt-0 mb-9",attrs:{"cols":"3"}},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('messages.password')))])])]),_c('v-col',{staticClass:"text-button align-self-center pb-0 pt-0",attrs:{"cols":"8"}},[_c('div',[_c('validation-provider',{ref:"confirm",attrs:{"name":"confirm","rules":"required|passwordRegex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('v-text-field',{ref:"confirm",attrs:{"clearable":"","type":"password","outlined":"","error-messages":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1)])],1),_c('v-row',[_c('v-col',{staticClass:"text-button align-self-center pt-0 mb-9",attrs:{"cols":"3"}},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('messages.rePassword')))])])]),_c('v-col',{staticClass:"text-button align-self-center pb-0 pt-0",attrs:{"cols":"8"}},[_c('div',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","type":"password","outlined":"","error-messages":errors[0] ? errors[0] : _vm.rePasswordCheck},model:{value:(_vm.rePassword),callback:function ($$v) {_vm.rePassword=$$v},expression:"rePassword"}})]}}],null,true)})],1)])],1),_c('v-row',{staticClass:"d-flex justify-end mt-3"},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"large":"","block":"","color":"accent","type":"submit"}},[_c('span',{staticClass:" font-weight-bold text-h5"},[_vm._v(" "+_vm._s(_vm.$t('messages.update'))+" ")])])],1),_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"large":"","block":"","color":"accent"},on:{"click":_vm.cancelPassword}},[_c('span',{staticClass:"text-h5 font-weight-bold pr-3 pl-3"},[_vm._v(_vm._s(_vm.$t('messages.cancel')))])])],1),_c('v-col',{attrs:{"cols":"1"}})],1)],1)]}}])})],1)],1),_c('v-row',{attrs:{"align":"center"}})],1),_c('v-card-actions')],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
                                      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('messages.close'))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }