<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card>
      <v-card-title primary-title />
      <v-card-text>
        <!--username and password-->
        <v-row class="mt-10">
          <v-col cols="6">
            <v-row>
              <v-col
                class="text-button align-self-center pt-0 mb-9"
                cols="3"
              >
                <div>
                  <span class="font-weight-bold">{{
                    $t('messages.userId')
                  }}</span>
                </div>
              </v-col>
              <v-col
                class="text-button align-self-center pb-0 pt-0"
                cols="8"
              >
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="userId"
                      disabled
                      outlined
                      :error-messages="errors[0]"
                    />
                  </validation-provider>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="text-button align-self-center pt-0 mb-9"
                cols="3 "
              >
                <div>
                  <span class="font-weight-bold">{{
                    $t('messages.name')
                  }}</span>
                </div>
              </v-col>
              <v-col
                class="text-button align-self-center pb-0 pt-0"
                cols="8"
              >
                <div>
                  <validation-provider>
                    <v-text-field
                      v-model="name"
                      clearable
                      outlined
                      disabled
                    />
                  </validation-provider>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="text-button align-self-center pt-0 mb-9"
                cols="3"
              >
                <div>
                  <span class="font-weight-bold">{{
                    $t('messages.managerId')
                  }}</span>
                </div>
              </v-col>
              <v-col
                class="text-button align-self-center pb-0 pt-0"
                cols="8"
              >
                <div>
                  <validation-provider>
                    <v-text-field
                      v-model="managerId"
                      clearable
                      outlined
                      disabled
                    />
                  </validation-provider>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <validation-observer v-slot="{ handleSubmit }">
                  <v-form
                    ref="form"
                    lazy-validation
                    @submit.prevent="handleSubmit(onSubmit)"
                  >
                    <v-row
                      v-for="(value, index) in fields"
                      :key="index"
                      class="align"
                    >
                      <v-col
                        v-if="value.type !== 'spacer'"
                        class="text-button align-self-center pt-0 mb-9"
                        cols="3"
                      >
                        <div>
                          <span class="font-weight-bold">{{
                            $t('messages.' + value.key)
                          }}</span>
                          <span class="red--text">
                            {{
                              value.validate
                                ? value.validate.includes('required')
                                  ? '*'
                                  : ''
                                : ''
                            }}</span>
                        </div>
                      </v-col>
                      <v-col
                        class="text-button align-self-center pb-0 pt-0"
                        cols="8"
                      >
                        <v-textarea
                          v-if="value.type === 'textarea'"
                          v-model="value.value"
                          clearable
                          outlined
                        />
                        <validation-provider
                          v-else-if="value.type === 'date-text'"
                          v-slot="{ errors }"
                          :name="value.key"
                          :rules="value.validate ? value.validate : ''"
                        >
                          <div v-if="'menu' in value">
                            <v-row>
                              <v-col cols="10">
                                <v-text-field
                                  v-model="value.value"
                                  :name="value.key"
                                  :disabled="value.disabled"
                                  clearable
                                  outlined
                                  autocomplete="off"
                                  :error-messages="errors[0]"
                                  :placeholder="$t(value.placeholder)"
                                  @keydown="checkDate"
                                >
                                  <template v-slot:append />
                                </v-text-field>
                              </v-col>
                              <v-col cols="2">
                                <v-menu
                                  :ref="'menu' + value.key"
                                  v-model="value.menu"
                                  :close-on-content-click="false"
                                  :return-value.sync="value.value"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      class="mt-4 mr-3"
                                      v-bind="attrs"
                                      left
                                      v-on="on"
                                    >
                                      mdi-calendar
                                    </v-icon>
                                  </template>
                                  <v-date-picker
                                    v-model="value.value"
                                    locale="ja-jn"
                                    no-title
                                    scrollable
                                    :max="value.max_date ? value.max_date : ''"
                                  >
                                    <v-spacer />
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="value.menu = false"
                                    >
                                      {{ $t('messages.cancel') }}
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="
                                        $refs['menu' + value.key][0].save(
                                          value.value
                                        )
                                      "
                                    >
                                      {{ $t('messages.ok') }}
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </div>
                          <div v-else>
                            <v-text-field
                              v-model="value.value"
                              :name="value.key"
                              clearable
                              :disabled="value.disabled"
                              outlined
                              :error-messages="errors[0]"
                              :placeholder="$t(value.placeholder)"
                              @keydown="checkDate"
                            />
                          </div>
                        </validation-provider>
                        <validation-provider
                          v-else-if="value.type === 'select'"
                          v-slot="{ errors }"
                          :name="value.key"
                          :rules="value.validate ? value.validate : ''"
                        >
                          <v-select
                            v-model="value.value"
                            :disabled="value.disabled"
                            :name="value.key"
                            outlined
                            :error-messages="errors[0]"
                            :items="value.items"
                            item-text="text"
                            item-value="value"
                            :placeholder="$t(value.placeholder)"
                          />
                        </validation-provider>
                        <validation-provider
                          v-else-if="value.type === 'text'"
                          v-slot="{ errors }"
                          :name="value.key"
                          :rules="value.validate ? value.validate : ''"
                        >
                          <v-text-field
                            v-model="value.value"
                            :disabled="value.disabled"
                            :name="value.key"
                            outlined
                            :error-messages="errors[0]"
                            clearable
                            :placeholder="$t(value.placeholder)"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-end mt-3">
                      <v-col
                        cols="2"
                        class="d-flex justify-end"
                      >
                        <v-btn
                          large
                          block
                          color="accent"
                          type="submit"
                        >
                          <span class=" font-weight-bold text-h5">
                            {{ $t('messages.update') }}
                          </span>
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="3"
                        class="d-flex justify-space-between"
                      >
                        <v-btn
                          large
                          block
                          color="accent"
                          @click="cancel"
                        >
                          <span class="text-h5 font-weight-bold pr-3 pl-3">{{ $t('messages.cancel') }}</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="1" />
                    </v-row>
                  <!--others-->
                  </v-form>
                </validation-observer>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <validation-observer v-slot="{ handleSubmit }">
              <v-form
                ref="form"
                lazy-validation
                @submit.prevent="handleSubmit(onSubmitPassword)"
              >
                <v-row>
                  <v-col
                    class="text-button align-self-center pt-0 mb-9"
                    cols="3"
                  >
                    <div>
                      <span class="font-weight-bold">{{
                        $t('messages.oldPassword')
                      }}</span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="8"
                  >
                    <div>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-text-field
                          v-model="oldPassword"
                          clearable
                          type="password"
                          outlined
                          :error-messages="errors[0]"
                        />
                      </validation-provider>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="text-button align-self-center pt-0 mb-9"
                    cols="3"
                  >
                    <div>
                      <span class="font-weight-bold">{{
                        $t('messages.password')
                      }}</span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="8"
                  >
                    <div>
                      <validation-provider
                        v-slot="{ errors }"
                        ref="confirm"
                        name="confirm"
                        rules="required|passwordRegex"
                      >
                        <v-text-field
                          ref="confirm"
                          v-model="password"
                          clearable
                          type="password"
                          outlined
                          :error-messages="errors[0]"
                        />
                      </validation-provider>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    class="text-button align-self-center pt-0 mb-9"
                    cols="3"
                  >
                    <div>
                      <span class="font-weight-bold">{{
                        $t('messages.rePassword')
                      }}</span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="8"
                  >
                    <div>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-text-field
                          v-model="rePassword"
                          clearable
                          type="password"
                          outlined
                          :error-messages="
                            errors[0] ? errors[0] : rePasswordCheck
                          "
                        />
                      </validation-provider>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-end mt-3">
                  <v-col
                    cols="2"
                    class="d-flex justify-end"
                  >
                    <v-btn
                      large
                      block
                      color="accent"
                      type="submit"
                    >
                      <span class=" font-weight-bold text-h5">
                        {{ $t('messages.update') }}
                      </span>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="3"
                    class="d-flex justify-space-between"
                  >
                    <v-btn
                      large
                      block
                      color="accent"
                      @click="cancelPassword"
                    >
                      <span class="text-h5 font-weight-bold pr-3 pl-3">{{ $t('messages.cancel') }}</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="1" />
                </v-row>

              <!--others-->
              </v-form>
            </validation-observer>
          </v-col>
        </v-row>

        <!--button-->
        <v-row align="center" />
      </v-card-text>
      <v-card-actions />
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import moment from 'moment'
  import { get } from 'vuex-pathify'
  import { email, min, required } from 'vee-validate/dist/rules'

  export default {
    name: 'ProfileAccount',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        code: '',
        password: '',
        oldPassword: '',
        rePassword: '',
        userId: '',
        name: '',
        managerId: '',
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        rePasswordCheck: '',
        profileDefault: {},
        fields: [
          {
            key: 'email',
            value: '',
            type: 'text',
            validate: 'required|email',
            placeholder: 'placeholder.email',
          },

          {
            key: 'contactPhone',
            value: '',
            type: 'text',
            validate: 'required|phone',
            placeholder: 'placeholder.contactPhone',
          },

          {
            key: 'contactAddress',
            value: '',
            type: 'textarea',
            placeholder: 'placeholder.contactAddress',
          },
        ],
      }
    },
    computed: {
      ...get('user', ['profile', 'message', 'status', 'error']),
    },
    watch: {
      message (value) {
        if (value === 'success') {
          this.showSnackBar('messages.success')
        } else if (value === 'updateSuccess') {
          this.showSnackBar('messages.updateSuccess')
        } else {
          this.showSnackBar(value)
        }
      },
      profile (value) {
        this.profileDefault = Object.assign({}, value)
        this.name = this.profileDefault.name
        this.userId = this.profileDefault.username
        this.managerId = this.profileDefault.type === 3 ? this.profileDefault.code : this.profileDefault.manageId
        this.code = this.profileDefault.code

        for (const [key, val] of Object.entries(this.profileDefault)) {
          const field = this.fields.find(o => o.key === key)
          if (field) {
            field.value = val
          }
        }
      },
      error (value) {
        this.showSnackBar(value)
      },

      rePassword (value) {
        this.rePasswordCheck =
          value === this.password
            ? ''
            : this.$t('validator.confirm_password_invalid')
      },
    },
    mounted () {
      // Define the rule globally
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })

      extend('email', {
        ...email,
        message: this.$t('validator.email_invalid'),
      })

      extend('min', {
        ...min,
        message: this.$t('validator.password_invalid'),
      })

      extend('date', {
        validate (value) {
          return moment(value, 'YYYY-mm-DD').format('YYYY-mm-DD') === value
        },
        message: this.$t('validator.invalid_date'),
      })
      extend('phone', {
        validate (value) {
          return /^[+]?[(]?[0-9]{4}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,5}$/.test(value)
        },
        message: this.$t('半角数字で入力してください。'),
      })

      extend('passwordRegex', {
        validate (value) {
          return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value)
        },
        message: '英大文字・英小文字・数字を組み合わせて8文字以上で入力してください',
      })
    },
    created () {
      this.$store.dispatch('user/getProfile')
    },
    methods: {
      showSnackBar (message) {
        this.snackbarMessage = this.$t(message)
        this.snackbar = true
        if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
        this.snackbarCloseInerval = setTimeout(() => {
          this.snackbar = false
        }, 5000)
      },
      onSubmit () {
        const payload = {
          email: this.fields.find(o => o.key === 'email').value,
          contactPhone: this.fields.find(o => o.key === 'contactPhone').value ? this.fields.find(o => o.key === 'contactPhone').value : '',
          contactAddress: this.fields.find(o => o.key === 'contactAddress').value ? this.fields.find(o => o.key === 'contactAddress').value : '',
        }
        this.$store.dispatch('user/updateProfile', payload)
      },
      onSubmitPassword () {
        if (this.rePasswordCheck) return
        const payload = {
          oldPassword: this.oldPassword,
          newPassword: this.password,
          confirmNewPassword: this.rePassword,
        }
        this.$store.dispatch('user/changePassword', payload)
      },
      cancel () {
        this.profileDefault = Object.assign({}, this.profile)
        this.name = this.profileDefault.name
        this.userId = this.profileDefault.username
        this.managerId = this.profileDefault.type === 3 ? this.profileDefault.code : this.profileDefault.manageId
        this.code = this.profileDefault.code

        for (const [key, val] of Object.entries(this.profileDefault)) {
          const field = this.fields.find(o => o.key === key)
          if (field) {
            field.value = val
          }
        }
      },
      cancelPassword () {
        this.rePassword = ''
        this.password = ''
        this.oldPassword = ''
      },
    },
  }
</script>

<style scoped>

</style>
